<template>
  <div>
    <strong class="">{{row.title}}</strong>
    <div>
      开始时间: {{row.startTime}}
    </div>
    <div>
      结束时间: {{row.endTime}}
    </div>
    <div>
      路演市场: {{(row.timeStu/60).toFixed(2)}}分钟/人 等待时间: {{(row.timeStuPrepare/60).toFixed(2)}}分钟/人
    </div>
    <div>
      专家评分时间: {{(row.timeExpert/60).toFixed(2)}}分钟
    </div>
    <div>
      合格分数线: {{row.qualifiedLine}}分
    </div>
    <div>
      分数计算规则: {{row.countRule==1?'取平均分':row.countRule==2?'去掉高分低分平均':''}}
    </div>
    <div>
      路演说明:
      <div v-html='row.showExplain'>

      </div>
    </div>

    <span class="dialog-footer">
      <el-button type="primary" @click="getappGetStuShowStatus(1)">{{btntext}}</el-button>
    </span>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeUnmount } from 'vue';
import { appListShow, appGetStuShowStatus, appStartStuShow } from '@/api/roadshow';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
const store = useStore();
const props = defineProps({
  roadshowId: {
    type: String,
    default: ''
  },
  row: Object,
})
const emit = defineEmits(['closs'])
const btntext = ref('进入路演')
let centerDialogVisible = true
let userol = 'stu'
const getappGetStuShowStatus = (type) => {
  if (type == 1 && store.state.type == 'zj') {
    if (props.row.showStep == 3 || props.row.showStep == 2) {
      emit('closs', false)
    }
    return
  }
  if (store.state.type == 'zj') {
    return
  }
  appGetStuShowStatus(props.roadshowId).then(res => {
    console.log(res)
    if (res.code == 200) {
      if (res.msg == '暂无承载数据') {
        ElMessage.error('未查询到数据,请稍后重试')
      }
      res = res.data
      let statuss = res.showStep
      if (userol == 'stu') {
        console.log(statuss)

        if (statuss == 1 || statuss == 0) {
          btntext.value = '路演尚未开始'
        } else if (statuss == 2 || statuss == 3) {
          console.log(123)
          let number = res.queueNumber
          let num = res.timePerson
          let min = num / 60
          let n = number
          if (n > 0) {
            btntext.value = `前面还有${number}位，预计等待${(min * n).toFixed(2)}分钟`
            if (type == 1) {
              ElMessage.warning('请等待前面的学员;路演完毕')

            }
          } else if (n == 0) {
            btntext.value = `请立即进入房间，超时将跳过路演`
            if (type == 1) {
              if (res.enterRoom) {
                emit('closs', false)

              } else {
                ElMessage.warning('暂时无法进入房间')
              }

            }
          } else if (n < 0) {
            btntext.value = `路演已经结束`
          }
        } else if (statuss == 4) {
          btntext.value = '路演已经结束'
        }
      } else {

      }
    }
  })
  if (!type) {
    setTimeout(() => {
      if (centerDialogVisible) {
        getappGetStuShowStatus()
      }
    }, 10000);
  }
}
onBeforeUnmount(() => {
  centerDialogVisible = false
})
getappGetStuShowStatus()

</script>

<style>
</style>