<template>
  <div style='height:100%'>
    <el-container style='height:100%'>
      <el-main style='margin-right: 30px;flex:1;display:flex;flex-direction: column;'>
        <div class="ambo"
          style="width: 100%;position: relative;min-height: 100px;box-sizing: border-box;align-items: flex-start;">

          <div class="flex_start flex-shrink" style="flex: 1;overflow: auto;background-color: transparent;">
            <div v-if="screen.id" class="user flex-shrink" style="border: none;">

            </div>
            <div class="user flex-shrink" v-for="item in expert" :key='item.id' :id='"vido-"+item.id'>
              <div class='streamBar_bar__ELPku'>
                {{item.nick}}
              </div>
            </div>
            <div class="user flex-shrink" style="border: none;background-color: transparent;">

            </div>
          </div>
          <div class="ambo user flex-shrink" v-if="screen.id" :id='"vido-"+lead.id'
            style="position: absolute;top: 0;left: 0;z-index: 10;">
            <div class='streamBar_bar__ELPku'>
              {{lead.nick}}
            </div>
          </div>
          <div class="ambo user flex-shrink" :id='"vido-"+host.id'
            style="position: absolute;top: 0;right: 0;z-index: 10;background-color: #ccc;">
            <div class='streamBar_bar__ELPku'>
              {{host.nick}}
              <div style="float: right;">

                <!-- <i class="el-icon-video-camera" :class='{noactive:0}'></i>
                  <i class="el-icon-microphone" :class='{noactive:0}' style="margin: 0 10px 0 5px;"></i> -->
              </div>
            </div>
            <!-- <div class="mask">
                <div style="height: 100%; width: 100%; position: absolute; background-color: #D8D8D8; top: 0; left: 0;"></div>
                <img style="width: 43px; height: 49px; z-index: 10;" src="/img/trtc/img/camera-max.png" alt="">
                <div style="height: 10px"></div>
                <div style="z-index: 10">摄像头未打开</div>
              </div> -->
          </div>
        </div>
        <el-row style="flex: 1;overflow: auto;background-color: transparent;">
          <el-col :span="24" style="height: 100%;margin:0;">
            <div class="grid-content bg-purple-dark" :id='"vido-"+(screen.id||lead.id)'
              style="height: 100%;background-color: #ccc;position:absolute;top: 0;left: 0;width: 100%;">
              <div class='streamBar_bar__ELPku' style="font-size: 33px;line-height:60px;height:60px">
                {{lead.nick}}
              </div>
              <div cless='pingmu' id='pingmu' style=' width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;'></div>
            </div>
          </el-col>
        </el-row>
      </el-main>
      <el-aside width="600px" v-if='type!=1'>
        <div style="font-size:16px">
          <block v-if="showtype">
            <span
              style="width:200px">{{showtype==1?'等待中':showtype==2?'正在路演':showtype==3?'超时等待':showtype==4?'评分中':''}}:</span>
            <block style='margin-left:10px'>{{timeupdata(showtimenumber)}}</block>

          </block>
        </div>
        <el-row type="flex">
          <el-col :span="24" class="flex_start">
            <img id="video-btn" style="width: 68px; height: 68px" @click="muteVideo"
              :src='localStream.video_?"/img/trtc/img/big-camera-on.png":"/img/trtc/img/big-camera-off.png"' alt="">
            <img id="mic-btn" style="width: 68px; height: 68px" @click="muteAudio"
              :src='localStream.audio_?"/img/trtc/img/big-mic-on.png":"/img/trtc/img/big-mic-off.png"' alt="">
            <el-button type="primary" style="margin-left:20px" size="small" @click="getListStuShow(row)">刷新
            </el-button>
            <el-button type="primary" size="small" v-if='type3==1&&sharetim.logout' @click="shareStream(row)">
              {{shareclient.id ==1?'关闭分享':'分享屏幕'}}</el-button>
            <!-- <el-button type="primary" size="small" v-if='type3==2' @click="dafen">打分</el-button> -->
          </el-col>
        </el-row>
        <div>

          <div v-if="fenshu" class="ambo" style="font-size:20px;height:200px">得分: {{fenshu}}</div>
          <div v-if="detailList.length">
            <el-form :model="form" label-width="160px">
              <el-form-item :label="item.content+'('+item.fraction+'分)'" v-for="item in detailList" :key='item.id'>
                <el-input style="width:100px" v-model="item.scoreReal" type="number" :max="item.fraction" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>

              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-aside>
    </el-container>
    <template v-if="centerDialogVisible3&&0">
      <Score ref="Score2" :stuid='stuid' :centerDialogVisible2='centerDialogVisible3' @guan='done'
        :roadshowId='roadshowId'></Score>

    </template>

  </div>
</template>
<script setup>
import { ref, reactive, onBeforeUnmount, onMounted, computed, nextTick, getCurrentInstance } from 'vue';
import {
  listStuShow,
  getStuShowStatus,
  appStartStuShow,
  appGetShowScore,
  appGetCurrentStuShowStatus,
  appGetCurrentStuForExpert,
  getShowEvaluateScore,
  updateShowEvaluateScore
} from '@/api/roadshow'
import { getUserInfoApi, appGetExpert } from '@/api';
import Score from './Score.vue';
import TRTC from 'trtc-js-sdk';
import TIM from 'tim-js-sdk';
import { useStore } from 'vuex';
import { genTestUserSig } from '@/assets/js/lib-generate-test-usersig.min.js';
import { ElMessage } from 'element-plus';
import { useMessage } from 'naive-ui';
const message = useMessage();
const centerDialogVisible2 = ref(false)
const centerDialogVisible3 = ref(false)
const store = useStore();
const { ctx: that } = getCurrentInstance()
const lead = ref({
  id: "",
  nick: '',
  userID: '',
  avatar: ''
})
const detailList = ref([])
const expert = ref([])
const host = ref({
  id: "",
  nick: '',
  userID: '',
  avatar: ''
})
const props = defineProps({
  roadshowId: {
    type: String,
    default: ''
  },
  row: Object,
  roomid: Number,
  centerDialogVisible: false,
})
function onSubmit () {
  updateShowEvaluateScore(form.value).then(
    res => {
      console.log(res)
      if (res.code == 200) {
        emit('guan', false)
        message.success('操作成功');
      }
    })
}
const emit = defineEmits(['closs'])
const client = ref('')
const tim = ref('')
const Room_id = ref('')
const showRoomId = ref('')
const type = ref('')
const showtimenumber = ref(0)
const showInfo = ref({})
const showtype = ref(0)
const showtimeset = ref(null)

function stustart () {
  if (store.state.type == 'zj') {
    appGetCurrentStuShowStatus(props.roadshowId).then(res => {
      console.log(res)
      showInfo.value = res.data
      processStu(res.data)
    })
  } else {
    appStartStuShow(props.roadshowId).then(res => {
      console.log(res)
      showInfo.value = res.data
      processStu(res.data)
      appGetShowScoretime()
    })
  }

}
const fenshu = ref('')
function appGetShowScoretime () {
  appGetShowScore(props.roadshowId).then(res => {
    // console.log(res)
    if (res.data) {
      fenshu.value = res.data

    }
    if (props.centerDialogVisible) {
      setTimeout(() => {
        appGetShowScoretime()
      }, 1000);
    }
  })
}

function processStu (item) {
  if (!item || !item.id) {
    return
  }
  if (item.timeStartReal) {
    let times = new Date(item.showTimeEnd).getTime() - new Date(item.serverTime).getTime()
    if (times / 1000 > 0) {
      showtiming(times / 1000)
      return
    } else {
      times = new Date(item.expertTimeEnd).getTime() - new Date(item.serverTime).getTime()
      // times = showInfo.value.timeExpert + times / 1000
      if (times > 0) {
        gradetimeing(times / 1000)
        return
      }
    }
  }
  if (item.prepareTimeEnd) {
    let times = new Date(item.prepareTimeEnd).getTime() - new Date(item.serverTime).getTime()
    if (times / 1000 > 0) {
      waitingtime(times / 1000)
      return
    }
  }
}
//学员路演计时
function showtiming (times) {
  showtype.value = 2
  showtimenumber.value = times || showInfo.value.timeStu
  console.log(showtimenumber.value)
  clearInterval(showtimeset.value)
  showtimeset.value = setInterval(() => {
    if (showtimenumber.value > 0) {
      showtimenumber.value--
    } else {
      clearInterval(showtimeset.value)

      stustart()
    }
  }, 1000);
}
//打分计时
function gradetimeing (times) {
  showtype.value = 4;
  showtimenumber.value = times || showInfo.value.timeExpert;
  clearInterval(showtimeset.value)
  showtimeset.value = setInterval(() => {
    if (showtimenumber.value > 0) {
      showtimenumber.value--
    } else {
      // getListStuShow(1)
      clearInterval(showtimeset.value)
      stustart()
    }
  }, 1000);
}
//等待计时
function waitingtime (times) {
  showtype.value = 1
  showtimenumber.value = times || showInfo.value.timeStuPrepare
  clearInterval(showtimeset.value)
  showtimeset.value = setInterval(() => {
    if (showtimenumber.value > 0) {
      showtimenumber.value--
    } else {
      clearInterval(showtimeset.value)
      stustart()
    }
  }, 1000);
}
//超时等待计时
function overtime () {
  showtype.value = 3
  showtimenumber.value = showInfo.value.timeStuPrepare
  clearInterval(showtimeset.value)
  showtimeset.value = setInterval(() => {
    if (showtimenumber.value > 0) {
      showtimenumber.value--
    } else {
      clearInterval(showtimeset.value)
      stustart()
    }
  }, 1000);
}
function timeupdata (value) {
  let result = parseInt(value)
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  let res = '';
  if (h !== '00') res += `${h}:`;
  if (m !== '00') res += `${m}:`;
  res += `${s}`;
  if (res == 'NaN:NaN:NaN') {
    res = '00'
  }
  return res;
}
//开始摄像头
function muteVideo () {
  let pop = false
  if (localStream.value.video_) {
    pop = localStream.value.muteVideo()
  } else {
    pop = localStream.value.unmuteVideo()
  }
  pop && (localStream.value.video_ = !localStream.value.video_)

}
//开启关闭麦克风
function muteAudio () {
  let pop = false
  if (localStream.value.audio_) {
    pop = localStream.value.muteAudio()
  } else {
    pop = localStream.value.unmuteAudio()
  }
  pop && (localStream.value.audio_ = !localStream.value.audio_)

}
const type3 = store.state.type == 'zj' ? ref(2) : ref(1);
async function open (showRoomIds, type) {
  let admin = studentMessage.userId
  let nick = studentMessage.stuName
  if (type == 1) {
    nick = nick + '（学员）'

  } else {
    nick = nick + '（专家）'
  }
  let { sdkAppId, userId, userSig } = {
    sdkAppId: genTestUserSig(admin).sdkAppId,
    userId: admin,
    userSig: genTestUserSig(admin).userSig
  }
  console.log(sdkAppId, userId, userSig)
  client.value = TRTC.createClient({
    mode: 'rtc',
    // useStringRoomId: true,
    sdkAppId,
    userId,
    userSig
  });
  client.value.off('*')
  tim.value = TIM.create({
    SDKAppID: sdkAppId,
  });
  Room_id.value = 'Room_' + showRoomIds
  showRoomId.value = showRoomIds
  let onSdkReady = function (event, e) {
    console.log(event, e)
    if (event == 1) {
      onSdkReady2()
      return
    }
    let promise2 = tim.value.updateMyProfile({
      nick,
    });
    promise2.then(function (imResponse) {
      console.log(imResponse)
      peerjoin()
      subscribe()
    }).catch(function (imError) {
      console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
      peerjoin()
      subscribe()
    });
    getListStuShow()
    initialize() //初始化本地流
    peerlive()
  };



  let { sdkAppId2, userId2, userSig2 } = {
    sdkAppId2: genTestUserSig(admin).sdkAppId,
    userId2: 'share' + admin,
    userSig2: genTestUserSig('share' + admin).userSig
  }
  let onSdkReady2 = function (event) {
    let promise2 = sharetim.value.updateMyProfile({
      nick: '（屏幕）',
    });
    promise2.then(function (imResponse) {
      console.log(imResponse)
      let promise = tim.value.joinGroup({ groupID: Room_id.value, type: TIM.TYPES.GRP_AVCHATROOM });
      promise.then(function (imResponse) {
        switch (imResponse.data.status) {
          case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
            break;
          case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
            console.log(imResponse.data.group); // 加入的群组资料
            break;
          case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
            break;
          default:
            break;
        }
        let promise = sharetim.value.logout();
        promise.then(function (imResponse) {
          console.log(imResponse.data); // 登出成功
          sharetim.value.off(TIM.EVENT.SDK_READY, onSdkReady2);
          tim.value.on(TIM.EVENT.SDK_READY, onSdkReady);

          tim.value.login({ userID: userId, userSig: userSig });
        }).catch(function (imError) {
          console.warn('logout error:', imError);
        });
      }).catch(function (imError) {
        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
      });

    }).catch(function (imError) {
      console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
    });
  };
  sharetim.value = TIM.create({
    SDKAppID: sdkAppId2,
  });
  sharetim.value.on(TIM.EVENT.SDK_READY, onSdkReady2);
  let promise1 = sharetim.value.login({ userID: userId2, userSig: userSig2 })

}
function confirmEnding (str, target) {
  // 请把你的代码写在这里
  var start = str.length - target.length;
  var arr = str.substr(start, target.length);
  if (arr == target) {
    return true;
  }
  return false;
}
function subscribe () {
  console.log('远端订阅 ');
  client.value.on('stream-added', event => {
    const remoteStream = event.stream;
    console.log('远端流增加: ' + remoteStream.getId());
    //订阅远端流
    client.value.subscribe(remoteStream);
  });
  client.value.on('client-banned', error => {
    console.error('client-banned observed: ' + error);
    alert('您被踢出房间')
    // 退出刷新页面
  });
  client.value.on('stream-subscribed', event => {
    console.log('远端订阅: ');

    const remoteStream = event.stream;
    console.log(remoteStream)
    if (remoteStream.getType() == 'auxiliary') {
      remoteStream.play('pingmu', {
        objectFit: 'contain'
      });
      return
    }
    let promise = tim.value.getGroupMemberProfile({
      groupID: Room_id.value,
      userIDList: [remoteStream.userId_], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
    });
    promise.then(function (imResponse) {
      console.log(imResponse)
      getListStuShow()
      console.log(imResponse.data.memberList[0]); // 群成员列表
      let nick = imResponse.data.memberList[0].nick
      if (confirmEnding(nick, '（学员）')) {
        if (localStream.value && localStream.value.getId && localStream.value.getId() != lead.value.id) {
          //新学员进入开始新学员路演计时
          showtiming()

        }
        if (store.state.type == 'zj') {
          dafen()
        }
        lead.value.remoteStream = remoteStream
        lead.value.id = remoteStream.getId()
        lead.value.nick = nick
        lead.value.userID = imResponse.data.memberList[0].userID
        lead.value.avatar = imResponse.data.memberList[0].avatar
      } else if (confirmEnding(nick, '（专家）')) {
        let pop = true
        expert.value.forEach(item => {
          if (item.userID == imResponse.data.memberList[0].userID) {
            item.id = remoteStream.getId()
            item.nick = nick
            item.userID = imResponse.data.memberList[0].userID
            item.avatar = imResponse.data.memberList[0].avatar
            pop = false
          }
        })
        if (pop) {
          expert.value.push({
            id: remoteStream.getId(),
            nick: nick,
            userID: imResponse.data.memberList[0].userID,
            avatar: imResponse.data.memberList[0].avatar,
          })
        }

      } else if (confirmEnding(nick, '主持人')) {
        host.value.id = remoteStream.getId()
        host.value.nick = nick
        host.value.userID = imResponse.data.memberList[0].userID
        host.value.avatar = imResponse.data.memberList[0].avatar
      } else if (confirmEnding(nick, '（屏幕）')) {
        screen.value.id = remoteStream.getId()
        screen.value.nick = nick
        screen.value.userID = imResponse.data.memberList[0].userID
        screen.value.avatar = imResponse.data.memberList[0].avatar
        if (lead.value.id) {
          console.log(lead.value.remoteStream)
          lead.value.remoteStream.stop()
          setTimeout(function () {
            lead.value.remoteStream.play('vido-' + lead.value.remoteStream.getId(), {
              objectFit: 'contain'
            });
          }, 0)
        }
      }
      setTimeout(function () {
        remoteStream.play('vido-' + remoteStream.getId(), {
          objectFit: 'contain'
        });
      }, 0)
    }).catch(function (imError) {
      console.warn('getGroupMemberProfile error:', imError);
    });
    // console.log('远端流订阅成功：' + remoteStream.getuserId());
    // 播放远端流


    // this.getId = remoteStream.getId()
    setTimeout(function () { remoteStream.play('remote_stream-' + remoteStream.getId()); }, 0)
    remoteStream.play('remote_stream-' + remoteStream.getId());
    console.log('remote_stream-' + remoteStream.getId())
  });
  // var notification = { "isAllCameraMuted": false, "isAllMicMuted": false, "speechMode": "FreeSpeech", "isSpeechApplicationForbidden": false, "isChatRoomMuted": true, "startTime": Date.parse(new Date()).toString().substr(0, 10), "isCallingRoll": false }
  // let promise = tim.value.createGroup({
  //   type: TIM.TYPES.GRP_PUBLIC,
  //   name: 'WebSDK',
  //   groupID: Room_id.value,
  //   notification: JSON.stringify(notification),
  //   joinOption: TIM.TYPES.JOIN_OPTIONS_FREE_ACCESS
  // });
  // promise.then(function (imResponse) { // 创建成功
  //   console.log('进房')
  //   join(showRoomId.value)
  // }).catch(function (imError) {
  //   joinGroup()

  // });
  joinGroup()
  // if (type == 1) {
  //   joinGroup()
  //   return
  // }
}
const shareStreams = ref({});
const shareclient = ref({});
const sharetim = ref({});
const Score2 = ref()
async function shareStream () {
  let admin = studentMessage.userId
  let { sdkAppId, userId, userSig } = {
    sdkAppId: genTestUserSig(admin).sdkAppId,
    userId: 'share' + admin,
    userSig: genTestUserSig('share' + admin).userSig
  }
  console.log(shareclient)

  if (shareclient.value.id) {
    await shareclient.value.unpublish(shareStreams.value)
    shareStreams.value.close()
    await shareclient.value.leave().then(() => {
      shareclient.value.id = ''
    }).catch(error => {
      console.error('leaving room failed: ' + error);
    });
    return
  } else {
    shareclient.value = TRTC.createClient({
      mode: 'rtc',
      sdkAppId,
      userId,
      userSig
    });
    try {
      await shareclient.value.join({ roomId: showRoomId.value });
      shareclient.value.id = 1


      // ShareClient join room success
    } catch (error) {
      // ShareClient join room failed
    }
  }
  shareStreams.value = TRTC.createStream({ screenAudio: true, screen: true, userId: admin });


  await shareStreams.value.initialize().then((res) => {
    console.log(res)

    shareclient.value
      .publish(shareStreams.value).then((res) => {
        console.log(res)
        console.log('本地流发布成功');

      })
      .catch(error => {
        console.error('本地流发布失败 ' + error);
      });
  }).catch(error => {
    console.error('初始化本地流失败 ' + error);
  });
  shareStreams.value.on('screen-sharing-stopped', e => {
    shareclient.value.unpublish(shareStreams.value)
    shareStreams.value.close()
    shareclient.value.leave().then(() => {
      shareclient.value.id = ''
    }).catch(error => {
      console.error('leaving room failed: ' + error);
    });
  })
}
function getListStuShow () { }
async function initialize () {
  let admin = studentMessage.userId
  const cameraList = await TRTC.getCameras();
  const micList = await TRTC.getMicrophones();
  const speakerList = await TRTC.getSpeakers();
  if (cameraList.length == 0) {
    alert('请检查摄像头是否连接')
  }
  if (micList.length == 0) {
    alert('请检查麦克风是否连接')
  }
  if (micList.length > 0 || cameraList.length > 0) {
    localStream.value = TRTC.createStream({ userId: admin, audio: micList.length > 0, video: cameraList.length > 0, mirror: true });
    localStream.value
      .initialize()
      .then((res) => {
        console.log('初始化本地流成功');
        // console.log(that.localStream)
        // 'local_stream' 是在 DOM 中的一个 div 标签的 ID
        // host.value = localStream.value
        // host.value.id = localStream.value.getId()
        // console.log(that.getGroupMembersInfo(admin).then(res=>{
        //   console.log(res)
        // }))
        getGroupMembersInfo(admin)
        // nextTick(() => {
        //   localStream.value.play('vido-' + host.value.id);
        // })
        client.value
          .publish(localStream.value)
          .then((res) => {
            console.log(res)
            console.log('本地流发布成功');
          })
          .catch(error => {
            console.error('本地流发布失败 ' + error);
          });
      })
      .catch(error => {
        console.error('初始化本地流失败 ' + error);
      });
  }
}


function peerjoin () {
  client.value.on('peer-join', event => {
    console.log(event)
    const userId = event.userId;
  });
}
function peerlive () {
  client.value.on('peer-leave', event => {
    console.log(event)
    const userId = event.userId;
    getpeerliveGroupMembersInfo(event.userId)
    getListStuShow(1)
  });
}
const screen = ref({ id: '', nick: '', userID: '', avatar: '' })
function getpeerliveGroupMembersInfo (id) {
  let promise = tim.value.getGroupMemberProfile({
    groupID: Room_id,
    userIDList: [id], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
  });
  promise.then(function (imResponse) {
    console.log(imResponse.data.memberList); // 群成员列表
    let nick = imResponse.data.memberList[0].nick
    if (confirmEnding(nick, '（学员）')) {
      lead.value.remoteStream = ''
      lead.value.id = ''
      lead.value.nick = ''
      lead.value.userID = ''
      lead.value.avatar = ''
    } else if (confirmEnding(nick, '（专家）')) {
      expert.value.forEach((item, index) => {
        if (item.userID == id) {
          expert.value.splice(index, 1)
        }
      })
    } else if (confirmEnding(nick, '主持人')) {
      host.value.id = ''
      host.value.nick = ''
      host.value.userID = ''
      host.value.avatar = ''
    } else if (confirmEnding(nick, '（屏幕）')) {
      screen.value.id = ''
      screen.value.nick = ''
      screen.value.userID = ''
      screen.value.avatar = ''
    }
  }).catch(function (imError) {
    console.warn('getGroupMemberProfile error:', imError);
  });
}
function joinGroup () {
  console.log(Room_id.value)
  let promise = tim.value.joinGroup({ groupID: Room_id.value, type: TIM.TYPES.GRP_AVCHATROOM });
  promise.then(function (imResponse) {
    join(showRoomId.value)
    switch (imResponse.data.status) {
      case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
        break;
      case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
        console.log(imResponse.data.group); // 加入的群组资料
        break;
      case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
        break;
      default:
        break;
    }
  }).catch(function (imError) {
    console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
  });
}
function join (roomId) {
  console.log(roomId)
  client.value
    .join({ roomId: roomId })
    .then(() => {
      getGroupMembersInfo(studentMessage.userId)
      if (localStream.value && localStream.value.id) {
        client.value
          .publish(localStream.value)
          .then((res) => {
            console.log('本地流发布成功');
            // console.log(res)
          })
          .catch(error => {
            console.error('本地流发布失败 ' + error);
          });
      }

    })
    .catch(error => {
      console.error('进房失败 ' + error);
    });
  // this.startGetAudioLevel()
}
function getGroupMembersInfo (id) {
  console.log(Room_id.value)
  console.log(id)
  let promise = tim.value.getGroupMemberProfile({
    groupID: Room_id.value,
    userIDList: [id], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
  });
  promise.then(function (imResponse) {
    console.log(imResponse.data.memberList); // 群成员列表
    let nick = imResponse.data.memberList[0].nick
    if (confirmEnding(nick, '（学员）')) {
      lead.value.remoteStream = localStream.value
      console.log(lead.value.localStream)
      lead.value.id = localStream.value.getId && localStream.value.getId()
      lead.value.nick = nick
      lead.value.userID = imResponse.data.memberList[0].userID
      lead.value.avatar = imResponse.data.memberList[0].avatar

    } else if (confirmEnding(nick, '（专家）')) {
      let pop = true
      expert.value.forEach(item => {
        if (item.userID == imResponse.data.memberList[0].userID) {
          item.remoteStream = localStream.value

          item.id = localStream.value.getId && localStream.value.getId()
          item.nick = nick
          item.userID = imResponse.data.memberList[0].userID
          item.avatar = imResponse.data.memberList[0].avatar
          pop = false
        }
      })
      if (pop) {
        expert.value.push({
          remoteStream: localStream.value,

          id: localStream.value.getId && localStream.value.getId(),
          nick: nick,
          userID: imResponse.data.memberList[0].userID,
          avatar: imResponse.data.memberList[0].avatar,
        })
      }

    } else if (confirmEnding(nick, '主持人')) {
      host.value.remoteStream = localStream.value

      host.value.id = localStream.value.getId && localStream.value.getId()
      host.value.nick = nick
      host.value.userID = imResponse.data.memberList[0].userID
      host.value.avatar = imResponse.data.memberList[0].avatar
    } else if (confirmEnding(nick, '（屏幕）')) {
      screen.value.remoteStream = localStream.value
      screen.value.id = localStream.value.getId && localStream.value.getId()
      screen.value.nick = nick
      screen.value.userID = imResponse.data.memberList[0].userID
      screen.value.avatar = imResponse.data.memberList[0].avatar
    }
    nextTick(() => {
      localStream.value.play('vido-' + localStream.value.getId());
    })
  }).catch(function (imError) {
    console.warn('getGroupMemberProfile error:', imError);
  });
}
const studentMessage = reactive({});
const localStream = ref({});
const stuid = ref('')
function done (done) {
  centerDialogVisible3.value = false
  centerDialogVisible2.value = false

  console.log(123)
}
const form = ref({})
function dafen () {
  console.log(6666)
  appGetCurrentStuForExpert(props.roadshowId).then(res => {
    if (res.data) {
      stuid.value = res.data
      getShowEvaluateScore(props.roadshowId, (stuid.value)).then(res => {
        form.value = res.data
        detailList.value = res.data.detailList
      })
    } else {
      ElMessage.error('暂无可以打分的学员')
    }
  })
}
// 使用生命周期函数
onMounted(() => {
  console.log('!!!!!!!!!!!!!');
  const isLogin_deng = computed(() => Boolean(store.state.token));
  const type = computed(() => store.state.type);
  console.log(isLogin_deng.value);
  if (isLogin_deng.value) {
    if (type.value == 'zj') {
      appGetExpert().then(res => res.data)
        .then(res => {
          console.log(res);
          Object.assign(studentMessage, res);
          // const { name, photo } = res;
          // formModel_deng.stuName = name;
          // formModel_deng.proof = photo;
          studentMessage.stuName = studentMessage.name

          let number = props.row.showRoomId
          open(number, 2)

        });
    } else {
      stustart()
      getUserInfoApi()
        .then(res => res.data)
        .then(res => {
          Object.assign(studentMessage, res);
          console.log(studentMessage)
          let number = props.row.showRoomId
          console.log(number)
          open(number, store.state.type == 'zj' ? 2 : 1)

        });
    }
  }
});
onBeforeUnmount(() => {
  console.log(13)
  try {
    localStream.value.stop('vido-' + localStream.value.getId());
    localStream.value.close()
    client.value.unpublish(localStream.value).then(() => {
      // 取消发布本地流成功
    });
    client.value.leave().then(() => {
      client.value.off('*')
    }).catch(error => {
      console.error('leaving room failed: ' + error);
    });
    shareclient.value.leave().then(() => {
      shareclient.value.id = ''
    }).catch(error => {
      console.error('leaving room failed: ' + error);
    });
  } catch (error) {

  }
})

</script>
<script>
export default {
  filters: {
    showScoreupdata (old, row) {
      if (old > 0 && row.showStatus == 3) {
        return '成绩 ' + old
      } else {
        return ''
      }
    },
    showStatusdata (old, row) {
      if (row.showing == true) {
        return '正在路演'
      }
      return old == 0 ? '未开始' : old == 1 ? '已结束' : old == 3 ? '不合格' : old == 4 ? '合格' : ''
    },
    timeupdata (value) {
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
      let res = '';
      if (h !== '00') res += `${h}:`;
      if (m !== '00') res += `${m}:`;
      res += `${s}`;
      return res;
    }
  },
}
</script>
<style scoped="scoped">
.ambo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user {
  width: 200px;
  height: 150px;
  box-sizing: border-box;
  /* border: 1px solid #000000; */
  position: relative;
}
.flex-shrink {
  flex-shrink: 0;
}
.flex_start .user {
  /* border-color: #0000FE; */
  background-color: #ccc;
}
.streamBar_bar__ELPku {
  box-sizing: border-box;
  padding-left: 4px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 20px;
  z-index: 99;
  font-size: 14px;
  line-height: 30px;
}

.noactive {
  opacity: 0.5;
}
.mask {
  width: 100%;
  height: 100%;
  color: #888;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.redbtn {
  background: green;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 10px;
}
.pingmu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.roadshowpop .el-dialog__body {
  height: 90%;
}
.bg {
  background-image: url(https://qjck.oss-cn-shanghai.aliyuncs.com/handupload/roadshowbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.bg .el-dialog {
  background-image: url(https://qjck.oss-cn-shanghai.aliyuncs.com/handupload/roadshowbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
