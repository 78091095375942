<template>
  <Layout footer>
    <div class="app-container text-base">
      <div class="flex items-center py-5 text-base border-b border-gray-300 pl-3" style="background-color: #faf9f9">
        <span class="mr-1">当前位置：</span>
        <NBreadcrumb>
          <NBreadcrumbItem @click="gotoHome">首页</NBreadcrumbItem>
          <NBreadcrumbItem>项目路演</NBreadcrumbItem>
        </NBreadcrumb>
      </div>
      <div class="flex">
        <el-table ref="singleTableRef" :data="tableData" highlight-current-row style="width: 100%">
          <!-- <el-table-column type="index" width="50" /> -->
          <el-table-column property="title" label="路演名称" />
          <el-table-column property="startTime" label="开始时间" />
          <el-table-column property="endTime" label="结束时间" />
          <el-table-column property="tenantName" label="路演机构" />
          <el-table-column property="className" label="路演班级" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button size="small" @click="enter(scope.$index, scope.row)">进入路演</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </Layout>
  <el-dialog v-model="centerDialogVisible" title="路演信息" width="30%" destroy-on-close center>
    <Pops :roadshowId='roadshowId' @closs='centerDialogVisible=false;centerDialogVisible2=true' :row='rows'
      :centerDialogVisible='centerDialogVisible'></Pops>
  </el-dialog>
  <div class='roadshowpop'>
    <el-dialog v-model="centerDialogVisible2" destroy-on-close title="项目路演" :fullscreen='true' center
      :before-close='done' class="bg">
      <Roadshowstudio :roadshowId='roadshowId' @closs='centerDialogVisible2=false' :row='rows'
        :centerDialogVisible='centerDialogVisible2'></Roadshowstudio>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Pops from './components/pops';
import Layout from '@/components/Layout';
import Roadshowstudio from './components/roadshowstudio';
import { appListShow, appGetStuShowStatus } from '@/api/roadshow';
import { ElMessage } from 'element-plus';

const router = useRouter();
const store = useStore();
const tableData = ref([])
const rows = ref({})
let appGetStuShowStatustime = null
const centerDialogVisible = ref(false)
const centerDialogVisible2 = ref(false)
let roadshowId = ref(0);
function gotoHome () {
  router.push('/');
}
getList()
function getList () {
  appListShow().then(res => {
    tableData.value = res.data
  })
}
function getappGetStuShowStatus () {
  //获取学员路演详情
  appGetStuShowStatustime = setInterval(() => {
    console.log(centerDialogVisible)
    if (centerDialogVisible.value == true) {
      console.log(13)
    } else {
      console.log(24)
      clearInterval(appGetStuShowStatustime)
    }
  }, 10000);
}
function done (done) {
  console.log(3333)
  done()

  location.reload();

}
function enter (index, row) {
  console.log(row)
  getList()
  getappGetStuShowStatus()
  rows.value = row
  roadshowId.value = row.id
  if (row.showStep == 3) {
    centerDialogVisible.value = true
  } else {
    ElMessage.error('路演尚未开始请刷新后重试')
  }
}
</script>

<style lang="scss" >
:deep(.n-breadcrumb-item) {
  font-size: 16px;
}

.left {
  width: 280px;
  margin-right: 60px;
  line-height: 60px;
}

.tab {
  padding-left: 35px;
  //transition-property: background, color;
  //transition-duration: 0.3s;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #eb5405;
  }
  &.active {
    background-color: #ffd2bd;
    color: #eb5405;
  }
}
.roadshowpop .el-dialog__body {
  height: 90%;
}
.roadshowpop .el-dialog {
  background-image: url(https://qjck.oss-cn-shanghai.aliyuncs.com/handupload/roadshowbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
