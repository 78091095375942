import request from '@/utils/request';
// 路演列表
export const appListShow = () =>
  request({
    url: '/api/blade-policy/roadshow/appListShow',
    method: 'get',
  });
// APP端-学员查询某个路演的状态（10秒轮训）
export const appGetStuShowStatus = (showId) =>
  request({
    url: '/api/blade-policy/roadshow/appGetStuShowStatus',
    method: 'get',
    params: {
      showId
    }
  });
// 项目路演-路演顺序
export const listStuShow = (showId) => {
  return request({
    url: '/api/blade-policy/roadshow/listStuShow',
    method: 'get',
    params: {
      showId
    }
  })
}
// 项目路演-获取当前路演学员详情
export const getStuShowStatus = (showId) => {
  return request({
    url: '/api/blade-policy/roadshow/getStuShowStatus',
    method: 'get',
    params: {
      showId
    }
  })
}

// 学员开始路演
export const appGetCurrentStuShowStatus = (showId) => {
  return request({
    url: '/api/blade-policy/roadshow/appGetCurrentStuShowStatus',
    method: 'get',
    params: {
      showId
    }
  })
}
// 学员开始路演
export const appStartStuShow = (showId) => {
  return request({
    url: '/api/blade-policy/roadshow/appStartStuShow',
    method: 'get',
    params: {
      showId
    }
  })
}
// 学员查询成绩appGetShowScore
export const appGetShowScore = (showId) => {
  return request({
    url: '/api/blade-policy/roadshow/appGetShowScore',
    method: 'get',
    params: {
      showId
    }
  })
}
//专家打分获取当前学员id
export const appGetCurrentStuForExpert = (showId) => {
  return request({
    url: '/api/blade-policy/roadshow/appGetCurrentStuForExpert',
    method: 'get',
    params: {
      showId
    }
  })
}
//获取打分列表getShowEvaluateScore
export const getShowEvaluateScore = (showId, stuId) => {
  return request({
    url: '/api/blade-policy/roadshow/getShowEvaluateScore',
    method: 'get',
    params: {
      showId,
      stuId
    }
  })
}

//获取打分列表getShowEvaluateScore
export const updateShowEvaluateScore = (data) => {
  return request({
    url: '/api/blade-policy/roadshow/updateShowEvaluateScore',
    method: 'post',
    data,
  })
}